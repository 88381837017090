<template>
  <div class="home">
    <el-row :gutter="20" v-if="departmentType==='project'" style="margin-top: 5px;">
      <el-col :span="4" v-for="(item,index) in cardListTwo" :key="index">
        <card :parentmsg="item"></card>
      </el-col>
    </el-row>
    <el-row :gutter="20" v-else>
      <el-col :span="4" v-for="(item,index) in cardList" :key="index" style="margin-top: 5px;">
        <card :parentmsg="item"></card>
      </el-col>
    </el-row>
    <el-row :gutter="20" style="margin-top: 15px;">
      <el-col :span="4" v-for="(item,index) in cardListThree" :key="index" style="cursor: pointer;">
        <card :parentmsg="item" @tankInfo="tankInfo(item)"></card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="dataContain flex leftDataContain" style="background-color: unset;">
          <div class="leftData  flex-column" style="margin-top: unset;padding: unset;width: 100%;">
            <div v-if="isCompany" class="flex-space-between sbJ">
              <card v-for="(card, ind) in cardListSb" :key="ind+'card'" :parentmsg="card" showbtn @jump="jumpSb(ind)"></card>
            </div>
            <div v-if="isCompany" style="height: 15px;flex-shrink: 0;"></div>
            <div style="border-radius: 6px;background-color: #fff;padding: 20px 20px 0;">
              <div class="title flex-space-between" :class="{xtitle: isCompany}">
                <span>当天吊篮巡检情况</span>
                <el-link v-if="isCompany" type="primary" :underline="false" @click="goDl">查看更多></el-link>
              </div>
              <div class="flex-space-between titleRows" :class="{xtitleRows: isCompany}">
                <div class="tab" :style="{fontSize: isCompany ? '16px' : ''}">应巡检数量</div>
                <div class="num" @click="showList(1)" :style="{fontSize: isCompany ? '20px' : ''}">{{ todayData.checkCount }}</div>
              </div>
              <div class="flex-space-between titleRows" :class="{xtitleRows: isCompany}">
                <div class="tab" :style="{fontSize: isCompany ? '16px' : ''}">已巡检数量</div>
                <div class="num" @click="showList(2)" :style="{fontSize: isCompany ? '20px' : ''}">{{ todayData.checkingCount }}</div>
              </div>
              <div class="flex-space-between titleRows" :class="{xtitleRows: isCompany}">
                <div class="tab" :style="{fontSize: isCompany ? '16px' : ''}">未巡检数量</div>
                <div class="num" @click="showList(3)" :style="{fontSize: isCompany ? '20px' : ''}">{{ todayData.notCheckCount }}</div>
              </div>
            </div>
            <div style="height: 15px;flex-shrink: 0;"></div>
            <div style="border-radius: 6px;background-color: #fff;padding: 20px 20px 0;flex: 1;">
              <div class="title flex-space-between"  :class="{xtitle: isCompany}">
                <span>待办事项</span>
                <el-link type="primary" :underline="false" @click="$refs.moreList.showMore()">查看更多></el-link>
              </div>
              <backlog-list ref="moreList"></backlog-list>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="dataContain flex leftDataContain">
          <div class="leftData  flex-column">
            <div class="title">出现隐患检查部位占比</div>
            <div id="main" class="pie flex-center"></div>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="dataContain flex leftDataContain">
          <div class="leftData  flex-column">
            <div class="title">前八项问题排行</div>
            <div v-for="(item,index) in checkList" :key="index" class="problemItem">
              <div>{{ item.name }}</div>
              <el-progress :percentage="item.value/5" :format="format"></el-progress>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!--  应巡检、已巡检、未巡检列表  -->
    <el-dialog
        ref="chooseRefs"
        title="详情"
        v-if="isShowList"
        :visible.sync="isShowList"
        @close="close"
        width="60%">
      <div class="content flex-column">
        <div class="cont">
          <el-table
              :data="XunJianList"
              :header-cell-style="{background:'#f5f5f5',color:'#333333'}"
          >
            <el-table-column align="center" label="吊篮位置">
              <template slot-scope="{ row }">
                <div type="primary">{{ row.location }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="departmentName"  label="编制机构" :show-overflow-tooltip="true"></el-table-column>
          </el-table>
          <div class="flex-center" style="padding:10px 0;">
            <el-pagination
                background
                :current-page.sync="searchForm.pageCurrent"
                :page-size="searchForm.pageSize"
                :total="XunJianTotal"
                layout="total, prev, pager, next"
                @current-change="handleSizeChangeData"
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import card from './components/card'
import * as echarts from 'echarts';
import {mapGetters} from "vuex";
import apis from "@/views/menus/api/home";

  export default {

    name: 'home',

    components: {
      card,
      BacklogList: () => import('./components/backlog-list')
    },

    props: {
    },

    data() {
      return {
        cardListSb: [
          {
            name:'年吊篮台班数量',
            url:require('../../assets/images/zsj.png'),
            num:0,
            key:'upCheckingCount',
            color:'#3396fb'
          },
          {
            name:'年机械台班数量',
            url:require('../../assets/images/zsj.png'),
            num:0,
            key:'upCheckingCount',
            color:'#3396fb'
          }
        ],
        activeName: 'first',
        departmentType:'company',
        isShowList:false,
        todayData:{},
        XunJianList:[],
        optionItems:{},
        searchForm:{
          pageCurrent:1,
          pageSize:10,
        },
        departmentCode:'',
        cardList:[
          {
            name:'项目总数量',
            url:require('../../assets/images/xmzs.png'),
            num:0,
            key:'projectCount',
            color:'#02A7F0'
          },
          {
            name:'用户数量',
            url: require('../../assets/images/yhsl.png'),
            key:'userCount',
            num:0,
            color:'#BFBF00'
          },
          {
            name:'吊篮总数量',
            url:require('../../assets/images/dlsl.png'),
            num:0,
            key:'totalCount',
            color:'#04F21C'
          },
          {
            name:'停用数量',
            url:require('../../assets/images/tysl.png'),
            num:0,
            key:'stopCount',
            color:'#D9001B'
          },
          {
            name:'待整改数量',
            url:require('../../assets/images/dzgsl.png'),
            num:0,
            key:'repairCount',
            color:'#13227A'
          },
          {
            name:'待复查数量',
            url:require('../../assets/images/dfcsl.png'),
            num:0,
            key:'reviewCount',
            color:'#D4237A'
          },
        ],
        cardListTwo:[
          {
            name:'吊篮总数量',
            url:require('../../assets/images/dlsl.png'),
            num:0,
            key:'totalCount',
            color:'#04F21C'
          },
          {
            name:'已停用数量',
            url:require('../../assets/images/tysl.png'),
            num:0,
            key:'stopCount',
            color:'#D9001B'
          },
          {
            name:'待整改数量',
            url:require('../../assets/images/dzgsl.png'),
            num:0,
            key:'repairCount',
            color:'#13227A'
          },
          {
            name:'超期未整改数量',
            url:require('../../assets/images/xmzs.png'),
            num:0,
            key:'timeoutNoRepairCount',
            color:'#02A7F0'
          },
          {
            name:'待复查数量',
            url:require('../../assets/images/dfcsl.png'),
            num:0,
            key:'reviewCount',
            color:'#D4237A'
          },
          {
            name:'已拆除数量',
            url: require('../../assets/images/yhsl.png'),
            num:0,
            key:'removeCount',
            color:'#BFBF00'
          },
        ],
        cardListThree:[
          {
            name:'上部已巡检数量',
            url:require('../../assets/images/yxj.png'),
            num:0,
            key:'upCheckingCount',
            color:'#67C23A'
          },
          {
            name:'下部已巡检数量',
            url:require('../../assets/images/yxj.png'),
            num:0,
            key:'downNotCheckCount1',
            color:'#67C23A'
          },
          {
            name:'上部未巡检数量',
            url:require('../../assets/images/wxj.png'),
            num:0,
            key:'upNotCheckCount',
            color:'#F56C6C'
          },
          {
            name:'下部未巡检数量',
            url:require('../../assets/images/wxj.png'),
            num:0,
            key:'downNotCheckCount',
            color:'#F56C6C'
          },
          {
            name:'当天在用吊篮数量',
            url:require('../../assets/images/syz.png'),
            num:0,
            key:'useCount',
            color:'#3396fb'
          },
          {
            name:'当天吊篮使用率',
            url: require('../../assets/images/syl.png'),
            num:0,
            key:'useRate',
            color:'#3396fb'
          },
        ],
        checkList:[],
        XunJianTotal:0,
        XunJianType:1,
      }
    },

    computed: {
      ...mapGetters([
        'companyId',
        'optionItem',
        'userInfo'
      ]),
      isProject(){
        return this.optionItem && this.optionItem.departmentType == 'project';
      },
      // 是否为集团人员
      isCompany(){
        return this.optionItem && this.optionItem.departmentType == 'company';
      }
    },

    watch: {
      optionItem:function value(val){
        this.optionItems = val
        this.departmentType = this.optionItems.departmentType
        this.departmentCode = this.optionItems.departmentCode
        this.init()
        this.initTopData()
        this.initBasketData()
        this.initProblemData()
        this.$refs.moreList.onRefresh()
      }
    },
    mounted() {
      this.optionItems = this.optionItem
      try {
        this.departmentType = this.optionItem.departmentType
        this.departmentCode = this.optionItem.departmentCode
        this.init()
        this.initTopData()
        this.initBasketData()
        this.initProblemData()
        this.$refs.moreList.onRefresh()
      }catch (e){
        console.log(e)
      }
    },

    methods: {
      init() {
        apis.getProblemPercent({
          departmentCode:this.departmentCode
        }).then((res)=>{
          // console.log(res,'res')
          let chartDom = document.getElementById('main');
          let myChart = echarts.init(chartDom);
          let option = {
            title: {
              text: '',
              left: 'center'
            },
            tooltip: {
              trigger: 'item',
              formatter: function (params) {
                return params.marker + params.name +'  ' + params.value + '%'
              }
            },
            legend: {
              orient: 'vertical',
              left: 'left'
            },
            series: [{
              type: 'pie',
              radius: '60%',
              label: {
                show: true,
                formatter: function (params) {
                  return params.name +'' + params.value + '%'
                }
              },
              data: res
            }]
          };
          option && myChart.setOption(option);
        })
      },
      initTopData(){
        apis.getTopData({
          departmentType:this.departmentType,
          departmentCode:this.departmentCode,
        }).then(res=>{
          if (this.departmentType == 'project'){
            this.$nextTick(()=>{
              this.cardListTwo[0].num = res.totalCount
              this.cardListTwo[1].num = res.stopCount
              this.cardListTwo[2].num = res.repairCount
              this.cardListTwo[3].num = res.timeoutNoRepairCount
              this.cardListTwo[4].num = res.reviewCount
              this.cardListTwo[5].num = res.removeCount
            })
          }else{
            this.$nextTick(()=>{
              this.cardList[0].num = res.projectCount
              this.cardList[1].num = res.userCount
              this.cardList[2].num = res.totalCount
              this.cardList[3].num = res.stopCount
              this.cardList[4].num = res.repairCount
              this.cardList[5].num = res.reviewCount

              this.cardListSb[0].num = res.totalMachineTeamCount
              this.cardListSb[1].num = res.totalLargeCount
            })
          }
          this.$nextTick(() => {
            this.cardListThree[0].num = res.upCheckingCount
            this.cardListThree[1].num = res.downCheckingCount
            this.cardListThree[2].num = res.upNotCheckCount
            this.cardListThree[3].num = res.downNotCheckCount
            this.cardListThree[4].num = res.useCount
            this.cardListThree[5].num = res.useRate + '%'
          })
        })
      },
      initBasketData(){
        apis.getTodayBasketData({
          departmentCode:this.departmentCode
        }).then(res=>{
          this.todayData = res
        })
      },
      close(){
        this.isShowList = false
        this.XunJianList = []
        this.searchForm.pageCurrent = 1
      },
      showList(type){
        this.XunJianType = type
        this.searchForm.departmentCode=this.departmentCode
        switch (type) {
          case 1:
            apis.getCheckPageByDay(this.searchForm).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
          case 2:
            apis.getCheckingCountByDay(this.searchForm).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
          case 3:
            apis.getNotCheckCountByDay(this.searchForm).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
          case 4:
            apis.upAndDownList({
              ...this.searchForm,
              departmentId: this.optionItem.departmentId,
              departmentType: this.optionItem.departmentType,
              iftwocode: 0
            }).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
          case 5:
            apis.upAndDownList({
              ...this.searchForm,
              departmentId: this.optionItem.departmentId,
              departmentType: this.optionItem.departmentType,
              iftwocode: 1
            }).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
          case 6:
            apis.totalAppCount({
              ...this.searchForm,
              departmentId: this.optionItem.departmentId,
              departmentType: this.optionItem.departmentType,
            }).then(res=>{
              this.XunJianList = res.list
              this.XunJianTotal = res.total
            })
            break
        }
        this.isShowList = true
      },
      tankInfo(item) {
        if (item.key == 'upNotCheckCount') this.showList(4)
        if (item.key == 'downNotCheckCount') this.showList(5)
        if (item.key == 'useCount') this.showList(6)
      },
      async handleSizeChangeData(val) {
        // console.warn(val)
        this.searchForm.pageCurrent = val
        this.showList(this.XunJianType)
      },
      initProblemData(){
        apis.getProblemChart({
          departmentCode:this.departmentCode
        }).then(res=>{
          this.checkList = res
        })
      },
      format(percentage) {
        return percentage === 100 ? '满' : `${percentage*5}`;
      },
      goDl() {
        this.$router.push({
          name: 'Security',
          params: {
            tabIndex: 1
          }
        })
      },
      jumpSb(index) {
        if (index) {
          this.$router.push({
            name: 'Security',
            params: {
              tabIndex: 3
            }
          })
        } else {
          this.$router.push({
            name: 'Security',
            params: {
              tabIndex: 2
            }
          })
        }

      }
    }
  }
</script>

<style lang="stylus">
  // @import "../../assets/var"
   .home{
     height: 100%;
     .dataContain{
       margin-top: 15px;
       //width: 555px;
       height: 625.5px;
       background: inherit;
       background-color: rgba(255, 255, 255, 1);
       border-radius: 6px;
       -moz-box-shadow: none;
       -webkit-box-shadow: none;
       box-shadow: none;
       .leftData{
        width 500px
        margin-top 20px
        text-align left
        .title{
          font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
          font-weight: 700;
          font-style: normal;
          font-size: 18px;
          margin-bottom 20px
        }
        .xtitle {
          margin-bottom 10px
        }
        .titleRows{
          border-top 1px dashed #8c939d
          padding 12px 0
          //margin-top 20px
          //margin-bottom 20px
          .num{
            cursor pointer
            font-family: '黑体', sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 26px;
            color: #000000;
            //margin-bottom 20px
          }
          .num:hover{
            cursor pointer
            font-family: '黑体', sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 26px;
            color: #3396fb;
            //margin-bottom 20px
          }
          .tab{
            //font-size: 21px;
            font-size: 18px;
            color: #AAAAAA;
            font-family: 'Arial Normal', 'Arial', sans-serif;
            font-weight: 400;
            font-style: normal;
          }
        }

         .xtitleRows {
           padding 5px 0
         }
        .problemItem{
          margin 10px 0
          font-family: '微软雅黑', sans-serif;
          font-weight: 400;
          font-style: normal;
          //font-size: 16px;
          font-size: 18px;
          color: #AAAAAA;
        }
       }

     }
     .leftDataContain{
       justify-content center
     }
     .pie{
       //padding:10px;
       height 100%
     }
   }
  .el-progress__text {
    margin-left 20px !important
  }
  .sbJ {
    >div {
      width 48.3%
    }
  }
    // background-color $white
</style>
