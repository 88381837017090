<template>
  <div class="cardContain flex-center-v" style="position: relative;" @click="$emit('tankInfo')">
    <div>
      <img :src="parentmsg.url" class="img" alt="" srcset="">
    </div>
    <div class="top-right">
      <div class="titleCard">{{parentmsg.name}}</div>
      <div class="num" :style="{color:parentmsg.color||'#000000'}">{{parentmsg.num}}</div>
    </div>
    <el-link v-if="showbtn" class="sbLink" type="primary" :underline="false" @click="$emit('jump')">查看更多></el-link>
  </div>
</template>

<script>
export default {
  name: "card",
  props:{
    parentmsg:{
      type:Object,
      default:()=>{}
    },
    showbtn: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
.cardContain{
  border-width: 0px;
  padding: 10px 0 10px 30px;
  background: inherit;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 6px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  .sbLink {
    position: absolute;
    bottom 10px;
    right 10px;
  }
  .img{
    width: 40px;
    height: 40px;
    margin-right: 25px;
  }
  .top-right{
    min-width: 130px;
    .titleCard{
      font-size:16px;
      text-align: left;
    }
    .num{
      text-align:left;
      font-family: '黑体', sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 24px;
    }
  }
}
</style>
